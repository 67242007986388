import React from 'react';
import { FaReact, FaVuejs, FaAngular, FaNodeJs, FaPython, FaJava, FaJs, FaCss3, FaHtml5, FaGit, FaDocker } from 'react-icons/fa';
import { SiRuby, SiTypescript } from 'react-icons/si';

// Mapping the icons based on the card type
const iconMap = {
  react: FaReact,
  vuejs: FaVuejs,
  angular: FaAngular,
  nodejs: FaNodeJs,
  python: FaPython,
  ruby: SiRuby,
  javascript: FaJs,
  typescript: SiTypescript,
  css3: FaCss3,
  html5: FaHtml5,
  git: FaGit,
  docker: FaDocker,
};

const FeaturedCard = ({ title, description, icon, link, theme }) => {
  // Choosing the icon dynamically based on the type of card
  const IconComponent = iconMap[icon] || FaReact;

  return (
<div className="featured-card">
<img src={icon} alt={title} className="card-icon" />
<h3>{title}</h3>
<p>{description}</p>
<a href={link} target="_blank" rel="noopener noreferrer">Learn More</a>
</div>
    
    
  );
};

export default FeaturedCard;





