import React from 'react';
import { FaReact, FaVuejs, FaAngular, FaNodeJs, FaPython, FaJava, FaJs, FaCss3, FaHtml5, FaGit, FaDocker } from 'react-icons/fa';
import { SiRuby, SiTypescript } from 'react-icons/si';


const DocumentationCard = ({ title, icon, link }) => {


  return (
    <a href={link} target="_blank" rel="noopener noreferrer" className="documentation-card">
      <div className="card-icon">
      <img src={icon} alt={title} className="card-icon" />
      </div>
      <h3>{title}</h3>
    </a>
  );
};

export default DocumentationCard;


