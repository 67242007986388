import React, { useState } from 'react';
import Navbar from './components/Navbar';
import Footer from './components/Footer';
import FeaturedCard from './components/FeaturedCard';
import DocumentationCard from './components/DocumentationCard';
import SearchBar from './components/SearchBar';
import './App.css';
import allDocs from './allDocs.json';

const featuredDocs = [
  {
    id: 1,
    title: 'EVM',
    description: 'The leading platform for innovative apps and blockchain networks',
    icon: 'https://cryptologos.cc/logos/ethereum-eth-logo.png?v=035',
    link: 'https://ethereum.org/en/developers/docs/',
  },
  {
    id: 2,
    title: 'SEI',
    description: 'Sei is the first parallelized EVM',
    icon: 'https://assets.coingecko.com/coins/images/28205/large/Sei_Logo_-_Transparent.png',
    link: 'https://www.docs.sei.io/endpoints/cosmos#cosmoss',
  },
  {
    id: 3,
    title: 'CosmWasm',
    description: 'CosmWasm is a smart contracting platform',
    icon: 'https://avatars.githubusercontent.com/u/52079682?s=200&v=4',
    link: 'https://cosmwasm.com/build/',
  },
];

 
function App() {
  const [filteredDocs, setFilteredDocs] = useState(allDocs);
  const [activeGroup, setActiveGroup] = useState('All');

  const handleSearch = (searchTerm) => {
    const filtered = allDocs.filter(doc =>
      doc.title.toLowerCase().includes(searchTerm.toLowerCase())
    );
    setFilteredDocs(filtered);
    setActiveGroup('All');
  };

  const handleGroupFilter = (group) => {
    if (group === 'All') {
      setFilteredDocs(allDocs);
    } else {
      const filtered = allDocs.filter(doc => doc.type === group);
      setFilteredDocs(filtered);
    }
    setActiveGroup(group);
  };

  const groups = ['All', ...new Set(allDocs.map(doc => doc.type))];

  return (
    <div className="App">
      <Navbar />
      <main className="content">
  <div className="centered-content">
    <h1>SEI Documentations</h1>
    <p>Explore documentation for various technologies and frameworks</p>
    <h2>Sei Common Information</h2>
  </div>
        <table>
          <tbody>
            <tr>
              <td>REST</td>
              <td><a href="https://rest.sei-apis.com" target="_blank" rel="noopener noreferrer">https://rest.sei-apis.com</a></td>
            </tr>
            <tr>
              <td>RPC</td>
              <td><a href="https://rpc.sei-apis.com" target="_blank" rel="noopener noreferrer">https://rpc.sei-apis.com</a></td>
            </tr>
            <tr>
              <td>EVM-RPC</td>
              <td><a href="https://evm-rpc.sei-apis.com" target="_blank" rel="noopener noreferrer">https://evm-rpc.sei-apis.com</a></td>
            </tr>
            <tr>
              <td>gRPC</td>
              <td>sei.grpc.kjnodes.com:443</td>
            </tr>
          </tbody>
        </table>
        <p>To see more, you can use the telegram bot: @Cosmos_Directory_Bot</p>
        <p>Good documentation of sei endpoints: <a href="https://www.docs.sei.io/endpoints/cosmos#cosmos" target="_blank" rel="noopener noreferrer">https://www.docs.sei.io/endpoints/cosmos#cosmos</a></p>


        <h2>Featured Documentation</h2>
        <div className="featured-grid">
          {featuredDocs.map(doc => (
            <FeaturedCard key={doc.id} {...doc} />
          ))}
        </div>

        <h2>All Documentation</h2>
        <div className="all-docs-section">
          <div className="search-sidebar">
            <h2>Find Documentation</h2>
            <SearchBar onSearch={handleSearch} />
            <div className="group-buttons">
              {groups.map(group => (
                <button
                  key={group}
                  className={`group-button ${activeGroup === group ? 'active' : ''}`}
                  onClick={() => handleGroupFilter(group)}
                >
                  {group} ({group === 'All' ? allDocs.length : allDocs.filter(doc => doc.type === group).length})
                </button>
              ))}
            </div>
          </div>
          <div className="documentation-grid">
            {filteredDocs.map(doc => (
              <DocumentationCard key={doc.id} {...doc} />
            ))}
          </div>
        </div>
      </main>
      <Footer />
    </div>
  );
}

export default App;