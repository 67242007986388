import React from 'react';

const Footer = () => {
  return (
    <footer className="footer">
      <div className="social-links">
        <a href="https://x.com/wassim50919165" target="_blank" rel="noopener noreferrer">Twitter</a>
      </div>
      <div className="footer-info">
        <p>&copy; 2023 Documentation Site. All rights reserved.</p>
      </div>
    </footer>
  );
};

export default Footer;
