import React from 'react';
import logo from '../Glowing-G.webp';

const Navbar = () => {
  return (
    <nav className="navbar">
      <div className="navbar-brand">
        <h1 className="navbar-title">SeiDocs</h1>
      </div>
      <div className="navbar-actions">
        <div className="social-links">
          <a href="https://x.com/wassim50919165" target="_blank" rel="noopener noreferrer">Twitter</a>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
